import React, {useEffect, useState, useRef }from 'react';
import PieChart, {
  Legend,
  Series,
  Label,
  Font,
  Connector,
} from 'devextreme-react/pie-chart';
import {
  Chart, Series as ChartSeries, CommonSeriesSettings, Export, Tooltip, Legend as ChartLegend, ArgumentAxis, Label as ChartLabel
} from 'devextreme-react/chart';
import OverviewService from "api/overview.service";
import './style.css';
import CenterTemplate from './centerTemplate.js';
import Toast from 'components/Toast';
import generatePDF, { Margin } from 'react-to-pdf';
import * as moment from 'moment';
import {LoadPanel as LoadingPanel} from 'devextreme-react/load-panel';
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
const assetsTagsFields = {
	notPairedToTag: {color: '#CCCCCC', label: 'Not paired to Tag'},
	online: {color: '#92D050', label: 'On-line'},
	offline: {color: '#D71109', label: 'Off-line'},
};

const assetsUtilizationFields = {
	rest: {color: '#92D050', label: 'At rest'},
	light: {color: '#6D6D6D', label: 'Light (< 30 min)'},
	medium: {color: '#FFC001', label: 'Medium (30-120 min)'},
	heavy: {color: '#D71109', label: 'Heavy (> 120 min)'},
	notPairedOrOffline: {color: '#CCCCCC', label: 'Not paired or Off-line'},
	utilizationNotAvailable: {color: '#010101', label: 'Utilization not available'},
};

const tagsBatteryFields = {
	healthy: {color: '#92D050', label: 'Healthy'},
	low: {color: '#FFC001', label: 'Low'},
	critical: {color: '#D71109', label: 'Critical'},
	offline: {color: '#CCCCCC', label: 'Off-line'},
};

const gatewaysFields = {
	online: {color: '#92D050', label: 'On-line'},
	offline: {color: '#D71109', label: 'Off-line'},
};

const tagsFields = {
	unpaired: {color: '#CCCCCC', label: 'Unpaired'},
	paired: {color: '#FFC001', label: 'Paired'},
};

const anchorsFields = {
	online: {color: '#92D050', label: 'On-line'},
	offline: {color: '#D71109', label: 'Off-line'},
};

const anchorsBatteryFields = {
	healthy: {color: '#92D050', label: 'Healthy'},
	low: {color: '#FFC001', label: 'Low'},
	critical: {color: '#D71109', label: 'Critical'},
	offline: {color: '#CCCCCC', label: 'Off-line'},
};

const initialToast = {
  isVisible: false,
  type: 'info',
  message: '',
  displayTime: 1000,
}

export default function Overview() {
  const downloadTargetRef = useRef();
	const [assets, setAssets] = useState([]);
	const [assetsTotal, setAssetsTotal] = useState('');
	const [tags, setTags] = useState([]);
	const [tagsTotal, setTagsTotal] = useState('');
	const [gateways, setGateways] = useState([]);
	const [gatewaysTotal, setGatewaysTotal] = useState('');
	const [assetsUtilization, setAssetsUtilization] = useState([]);
	const [tagsBattery, setTagsBattery] = useState([]);
	const [anchors, setAnchors] = useState([]);
	const [anchorsBattery, setAnchorsBattery] = useState([]);
	const [anchorsTotal, setAnchorsTotal] = useState('');
	const [toastConfig, setToastConfig] = useState(initialToast);
	const [eventWarning, setEventWarning] = useState(0);
	const [eventAlarm, setEventAlarm] = useState(0);
	const [eventCrictical, setEventCrictical] = useState(0);
	const [eventInformation, setEventInformation] = useState(0);
	const [eventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(false);
	useEffect(() => {
    getOverviewAnalytics()
  },[]);


	//Get analytics for assets and tags
  const getOverviewAnalytics = async() => {
    OverviewService.fetch(timeZone).then((response) => {
      const result = response.data;
      if(result.error) {
        setToastConfig({
          ...toastConfig,
          message: result.error.message,
          type: 'error',
        });
      } else {
        const assetsResult = [];
        const tagsResult = [];
        const tagsBatteryResult = [];
        const anchorsResult = [];
        const anchorsBatteryResult = [];
        const gatewaysResult = [];
        const assetsUtilizationResult = [];
        customizeBarData(result.data?.eventData || [])
        // Convert the assets json into an array suitable for devextreme-react/pie-chart
        Object.keys(result.data.assets.report).map((item) => {
          assetsResult.push(
            {
              label: item,
              val: result.data.assets.report[item],
              tag: 'Status',
            }
          )
        });
        setAssetsTotal(result.data.assets.total);
        setAssets(assetsResult);

        // Convert the assets utilization json into an array suitable for devextreme-react/pie-chart
        Object.keys(result.data.assetsUtilization.report).map((item) => {
          assetsUtilizationResult.push({label: item, val: result.data.assetsUtilization.report[item], tag: 'Utilization', tag1: 'last 24h'})
        });
        setAssetsUtilization(assetsUtilizationResult);

        // Convert the tags json into an array suitable for devextreme-react/pie-chart
        Object.keys(result.data.tags.report).map((item) => {
          tagsResult.push({label: item, val: result.data.tags.report[item], tag: 'Pairing', tag1: 'Status'})
        });
        setTagsTotal(result.data.tags.total);
        setTags(tagsResult);

        // Convert the tags batteries json into an array suitable for devextreme-react/pie-chart
        Object.keys(result.data.tagsBattery.report).map((item) => {
          tagsBatteryResult.push({label: item, val: result.data.tagsBattery.report[item], tag: 'Battery'})
        });
        setTagsBattery(tagsBatteryResult);

        // Convert the gateways json into an array suitable for devextreme-react/pie-chart
        Object.keys(result.data.gateways.report).map((item) => {
          gatewaysResult.push({label: item, val: result.data.gateways.report[item], tag: 'Status'})
        });
        setGatewaysTotal(result.data.gateways.total);
        setGateways(gatewaysResult);

        // Convert the anchors json into an array suitable for devextreme-react/pie-chart
        Object.keys(result.data.anchors.report).map((item) => {
          anchorsResult.push({label: item, val: result.data.anchors.report[item], tag: 'Status'})
        });
        setAnchorsTotal(result.data.anchors.total);
        setAnchors(anchorsResult);

        // Convert the anchors batteries json into an array suitable for devextreme-react/pie-chart
        Object.keys(result.data.anchorsBattery.report).map((item) => {
          anchorsBatteryResult.push({label: item, val: result.data.anchorsBattery.report[item], tag: 'Battery'})
        });

        setAnchorsBattery(anchorsBatteryResult);
      }
    })
  }

  const handleChart = () => {
    window.location.href = "/#/events"
  }

  const onLegendClick = (e) => {
    e.target.isVisible() ? e.target.hide() : e.target.show();
  }

  const customizeAssetsPoint = (event) => {
    return {color: assetsTagsFields[event.argument].color}
  }

  const customizeAssetsLabel = (event) => {
    return assetsTagsFields[event.pointName].label
  }

  const customizeTagsPoint = (event) => {
    return {color: tagsFields[event.argument].color}
  }

  const customizeTagsLabel = (event) => {
    return tagsFields[event.pointName].label
  }

  const customizeGatewaysPoint = (event) => {
    return {color: gatewaysFields[event.argument].color}
  }

  const customizeGatewaysLabel = (event) => {
    return gatewaysFields[event.pointName].label
  }

  const customizeLabelText = (event) => {
    return event.value;
  }

  const customizeAssetsUtilizationPoint = (event) => {
    return {color: assetsUtilizationFields[event.argument].color}
  }

  const customizeAssetsUtilizationLabel = (event) => {
    return assetsUtilizationFields[event.pointName].label
  }

  const customizeTagsBatteryPoint = (event) => {
    return {color: tagsBatteryFields[event.argument].color}
  }

  const customizeTagsBatteryLabel = (event) => {
    return tagsBatteryFields[event.pointName].label
  }

  const customizeAnchorsPoint = (event) => {
    return {color: anchorsFields[event.argument].color}
  }

  const customizeAnchorsLabel = (event) => {
    return anchorsFields[event.pointName].label
  }

  const customizeAnchorsBatteryPoint = (event) => {
    return {color: anchorsBatteryFields[event.argument].color}
  }

  const customizeAnchorsBatteryLabel = (event) => {
    return anchorsBatteryFields[event.pointName].label
  }

  const customizeAnchorsBatteryTooltip = (arg) => {
    return {
      text: `${arg.valueText}`,
    };
  }

  const customizeEventsLabel = (arg) => {
    return {
      text: `${arg.valueText}`,
    };
  }

  const sortLegendItems = (items) => {
    return items.sort((a, b) => {
        let itemA = a.text.toLowerCase();
        let itemB = b.text.toLowerCase();
        if(itemA < itemB) return -1;
        if(itemA > itemB) return 1;
        return 0;
    });
  }

  const customizeBarData = async (eventListing) => {

    let arr = [];
    let start_time = moment().subtract(24, 'hours');
    let end_time = moment();

    const maxBars = 25

    let hours = end_time.diff(start_time, 'days')
    hours = hours===0?1:hours;
    for(let i=0;i<=maxBars;i++){
      arr.push(moment(start_time).add(i*hours, 'hours').startOf('hour').format("YYYY-MM-DD HH:mm:ss"));
    }

    const listkeys = eventListing
    let data=[];
    let alarmCount = 0, warningCount = 0,informationCount = 0, criticalCount = 0
    for(let i=0;i<arr.length;i++){

        let all_find_time = listkeys.filter(element => (moment(element.time) >= moment(arr[i])) && (moment(element.time) <= moment(arr[i]).add((hours * 60) - 1, 'minutes') ))
        if(all_find_time.length > 0) {
            let criticals = all_find_time.filter(element => element.severity === 'Critical').length;
            let alarms = all_find_time.filter(element => element.severity === 'Alarm').length;
            let warnings = all_find_time.filter(element => element.severity === 'Warning').length;
            let informations = all_find_time.filter(element => element.severity === 'Information').length;

            alarmCount += parseInt(alarms) || 0
            criticalCount += parseInt(criticals) || 0
            warningCount += parseInt(warnings) || 0
            informationCount += informations || 0

            data.push({
                time: moment(arr[i]).format('yyyy-MM-DD HH:mm'),
                critical: criticals,
                alarm: alarms,
                warning: warnings,
                information: informations,
            });
        } else {
            data.push({
              time: moment(arr[i]).format('yyyy-MM-DD HH:mm'),
              critical: 0,
              alarm: 0,
              warning: 0,
              information: 0,
            })
        }
    }
    setEventData(data);
    setEventAlarm(alarmCount)
    setEventCrictical(criticalCount)
    setEventInformation(informationCount)
    setEventWarning(warningCount)
  }

  const downloadReport = async() => {
    setLoading(true);
    await generatePDF(downloadTargetRef, {
      filename: `overview-${moment().format('YYYY-MM-DD-HH-mm-ss')}.pdf`,
      page: { margin: Margin.LARGE }
    });
    setLoading(false);
  }

  return (
    <>
    <div id="full-page-content-overview" className="page-content" ref={downloadTargetRef}>
      <div className='overviewcontainer'>
				<div className='pageFullWidth' style={{background: "white"}}>
					<h5 className='marginZero'><b>Events</b></h5>
          <div className='reportBtnDiv'>
            <button id="generatePdfButton" className='download_btn' alt="Download Report" title="Download Report" type="button" onClick={() => downloadReport('overview')}>
              <img src="../icons/reports.svg" alt="Download Report" />
            </button>
          </div>
					<p>In last 24 hours there were <b>{eventWarning} warning(s)</b>, <b>{eventAlarm} alarm(s)</b>, <b>{eventCrictical} critical events(s)</b> and <b>{eventInformation} information event(s)</b>.</p>
					<div className='analyticsContainer'>
						<div className='eventAnalytics'>
							<Chart
                id="overviewChart"
                dataSource={eventData}
                onPointClick={handleChart}
                onLegendClick={onLegendClick}
                height={180}
                size={{ width: '100%', height: 'auto' }}
                >
                  <ArgumentAxis>
                    <ChartLabel wordWrap="normal"  />
                </ArgumentAxis>
                <CommonSeriesSettings
                  argumentField="time"
                  type="stackedBar"
                ></CommonSeriesSettings>
                <ChartSeries
                  valueField="critical"
                  name="Critical"
                  color="#D71109"
                  />
                  <ChartSeries
                  valueField="alarm"
                  name="Alarm"
                  color="#FFC001"
                  />
                  <ChartSeries
                  valueField="warning"
                  name="Warning"
                  color="#6D6D6D"
                  />
                <ChartSeries
                  valueField="information"
                  name="Information"
                  color="#CCCCCC"
                  />
                <ChartLegend
                  verticalAlignment="left"
                  horizontalAlignment="left"
                  itemTextPosition="left"
                  // customizeItems={sortLegendItems}
                  />
                <Export enabled={false} />
                <Tooltip
                  enabled={true}
                  horizontalAlignment="left"
                  customizeTooltip={customizeEventsLabel}
                  />
              </Chart>
            </div>
          </div>
        </div>
      </div>

      <div className='overviewcontainer'>
				<div className='pageFullWidth'>
					<h5><b>Assets</b> {assetsTotal} total</h5>
					<div className='analyticsContainer'>
						<div className='analytics'>
							<PieChart
								id="pieAssetsFirst"
								type="doughnut"
								palette="Soft Pastel"
								style={{width: '100%', height: 180}}
								dataSource={assets}
								innerRadius={0.60}
								sizeGroup="piesGroup"
								centerRender={CenterTemplate}
								customizePoint={customizeAssetsPoint}
							>
								<Series argumentField="label">
									<Label visible={true} position="inside" backgroundColor={'transparent'} customizeText={customizeLabelText}>
                    <Font size={12} weight={600} color={"#FFFFFF"} />
                    <Connector visible={false} />
									</Label>
								</Series>
								<Legend
									margin={5}
									horizontalAlignment="left"
									verticalAlignment="center"
									customizeText={customizeAssetsLabel}
								/>
                <Tooltip enabled={true} customizeTooltip={customizeLabelText} />
							</PieChart>
						</div>
						<div className='analytics'>
							<PieChart
								id="pieAssetsUtilization"
								type="doughnut"
								palette="Soft Pastel"
								style={{width: '100%', height: 180}}
								dataSource={assetsUtilization}
								innerRadius={0.60}
								sizeGroup="piesGroup"
								centerRender={CenterTemplate}
								customizePoint={customizeAssetsUtilizationPoint}
							>
								<Series argumentField="label">
									<Label visible={true} position="inside" backgroundColor={'transparent'} customizeText={customizeLabelText}>
										<Font size={12} weight={600} color={"#FFFFFF"} />
                    <Connector visible={false} />
									</Label>
								</Series>
								<Legend
									margin={5}
									horizontalAlignment="left"
									verticalAlignment="center"
									customizeText={customizeAssetsUtilizationLabel}
								/>
                <Tooltip enabled={true} customizeTooltip={customizeLabelText} />
							</PieChart>
						</div>
					</div>
				</div>
      </div>

      <div className='overviewcontainer'>
        <div className='pageFullWidth'>
					<h5><b>Tags</b> {tagsTotal} total</h5>
					<div className='analyticsContainer'>
						<div className='analytics'>
							<PieChart
								id="pieTagsFirst"
								type="doughnut"
								palette="Soft Pastel"
								style={{width: '100%', height: 180}}
								dataSource={tags}
								innerRadius={0.60}
								sizeGroup="piesGroup"
								centerRender={CenterTemplate}
								customizePoint={customizeTagsPoint}
							>
								<Series argumentField="label" colorField="color">
									<Label visible={true} position="inside" backgroundColor="transparent" customizeText={customizeLabelText}>
										<Font size={12} weight={600} color={"#FFFFFF"} />
                    <Connector visible={false} />
									</Label>
								</Series>
								<Legend
									margin={5}
									horizontalAlignment="left"
									verticalAlignment="center"
									customizeText={customizeTagsLabel}
								/>
                <Tooltip enabled={true} customizeTooltip={customizeLabelText} />
							</PieChart>
						</div>
						<div className='analytics'>
							<PieChart
								id="pieTagsBattery"
								type="doughnut"
								palette="Soft Pastel"
								style={{width: '100%', height: 180}}
								dataSource={tagsBattery}
								innerRadius={0.60}
								sizeGroup="piesGroup"
								centerRender={CenterTemplate}
								customizePoint={customizeTagsBatteryPoint}
							>
								<Series argumentField="label" colorField="color">
									<Label visible={true} position="inside" backgroundColor="transparent" customizeText={customizeLabelText}>
                    <Font size={12} weight={600} color={"#FFFFFF"} />
                    <Connector visible={false} />
									</Label>
								</Series>
								<Legend
									margin={5}
									horizontalAlignment="left"
									verticalAlignment="center"
									customizeText={customizeTagsBatteryLabel}
								/>
                <Tooltip enabled={true} customizeTooltip={customizeLabelText} />
							</PieChart>
						</div>
					</div>
				</div>
      </div>

      <div className='overviewcontainer'>
				<div className='pageFullWidth'>
        <h5><b>Anchors</b> {anchorsTotal} total</h5>
					<div className='analyticsContainer'>
						<div className='analyticsAnchorsGateways'>
							<PieChart
								id="pieAnchorsFirst"
								type="doughnut"
								palette="Soft Pastel"
								style={{width: '100%', height: 180}}
								dataSource={anchors}
								innerRadius={0.60}
								sizeGroup="piesGroup"
								centerRender={CenterTemplate}
								customizePoint={customizeAnchorsPoint}
							>
								<Series argumentField="label" colorField="color">
									<Label visible={true} position="inside" backgroundColor={'transparent'} customizeText={customizeLabelText}>
										<Font size={12} weight={600} color={"#FFFFFF"} />
                    <Connector visible={false} />
									</Label>
								</Series>
								<Legend
									margin={5}
									horizontalAlignment="left"
									verticalAlignment="center"
									customizeText={customizeAnchorsLabel}
								/>
                <Tooltip enabled={true} customizeTooltip={customizeLabelText} />
							</PieChart>
						</div>
						<div className='analytics'>
							<PieChart
								id="pieAnchorsBattery"
								type="doughnut"
								palette="Soft Pastel"
								style={{width: '100%', height: 180}}
								dataSource={anchorsBattery}
								innerRadius={0.60}
								sizeGroup="piesGroup"
								centerRender={CenterTemplate}
								customizePoint={customizeAnchorsBatteryPoint}
							>
								<Series argumentField="label" colorField="color">
									<Label visible={true} position="inside" backgroundColor={'transparent'} customizeText={customizeLabelText}>
										<Font size={12} weight={600} color={"#FFFFFF"} />
                    <Connector visible={false} />
									</Label>
								</Series>
								<Legend
									margin={5}
									horizontalAlignment="left"
									verticalAlignment="center"
									customizeText={customizeAnchorsBatteryLabel}
								/>
                <Tooltip enabled={true} customizeTooltip={customizeAnchorsBatteryTooltip} />
							</PieChart>
						</div>
					</div>
				</div>
      </div>

      <div className='overviewcontainer'>
				<div className='pageFullWidth'>
        <h5><b>Gateways</b> {gatewaysTotal} total</h5>
					<div className='analyticsContainer'>
						<div className='analyticsAnchorsGateways'>
							
							<PieChart
								id="pieGatewaysFirst"
								type="doughnut"
								palette="Soft Pastel"
								style={{width: '100%', height: 180}}
								dataSource={gateways}
								innerRadius={0.60}
								sizeGroup="piesGroup"
								centerRender={CenterTemplate}
								customizePoint={customizeGatewaysPoint}
							>
								<Series argumentField="label" colorField="color" hoverMode="allSeriesPoints">
									<Label visible={true} position="inside" backgroundColor={'transparent'} customizeText={customizeLabelText}>
										<Font size={12} weight={600} color={"#FFFFFF"} />
                    <Connector visible={false} />
									</Label>
								</Series>
								<Legend
									margin={5}
									horizontalAlignment="left"
									verticalAlignment="center"
									customizeText={customizeGatewaysLabel}
								/>
                <Tooltip enabled={true} customizeTooltip={customizeLabelText} />
							</PieChart>
			    </div>
				</div>
			</div>
  </div>
  <LoadingPanel
        shadingColor="rgba(0,0,0,0.4)"
        visible={loading}
        showIndicator={true}
        showPane={true}
        shading={true}
        closeOnOutsideClick={false}
      />
      </div>

      <Toast
        setParentToastConfig={setToastConfig}
        toastBody={toastConfig}
      />
    </>
  );

}